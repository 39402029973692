
.Status {
  font-size: 8pt;
}

.Footer {
  font-size: 6pt;
}

img.logo {
  height: 50px;
  float: left;
}

form.LoginPage {
  width: 50%;
}

.ra-search-field,  .css-2b097c-container {
    visibility: hidden;
}

table.table {
    margin-top: -30px;
}